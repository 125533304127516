import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import ReactPlayer from "react-player";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { BgImage } from "gbimage-bridge";
import LazyLoad from "react-lazyload";

const FAQPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "faq" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            altText
            publicUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
                original {
                  width
                  height
                }
              }
            }
          }
        }
        faqPageFieldGroups {
          faqAboutSection {
            content
            heading
            subHeading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          faqIntroSection {
            description
            heading
            subHeading
            cta {
              target
              title
              url
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          faqPageFaqSection {
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            faq {
              ... on WpFaq {
                id
                faqFields {
                  answer
                  page
                  question
                }
              }
            }
          }
          faqVideoWithBackgroundImageSection {
            heading
            backgroundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            video {
              altText
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, faqPageFieldGroups },
  } = data;

  const {
    faqIntroSection,
    faqPageFaqSection,
    faqAboutSection,
    faqVideoWithBackgroundImageSection,
  } = faqPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;
  // const pluginImage = getImage(data.heroImg.childImageSharp.gatsbyImageData);

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "FAQ",
        item: {
          url: `${siteUrl}/faq`,
          id: `${siteUrl}/faq`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/faq`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.altText}`,
            },
          ],
        }}
      />

      <div>
        <Layout>
          {faqIntroSection &&
            !checkPropertiesForNull(faqIntroSection, ["heading"]) && (
              <Hero
                title={
                  <>
                    {faqIntroSection?.heading}
                    <br className="" />{" "}
                    <span className="text-secondary">
                      {faqIntroSection?.subHeading}
                    </span>
                  </>
                }
                description={
                  <SafeHtmlParser htmlContent={faqIntroSection?.description} />
                }
                img={
                  faqIntroSection.image?.localFile.childImageSharp
                    .gatsbyImageData
                }
                imgAlt={faqIntroSection.image?.altText}
                ctaButton={faqIntroSection?.cta}
              />
            )}
          {faqVideoWithBackgroundImageSection &&
            !checkPropertiesForNull(faqVideoWithBackgroundImageSection, [
              "heading",
            ]) && (
              <section
                style={{ overflow: "hidden" }}
                className="position-relative bg-secondary pt-5 pt-lg-0 pt-xl-7 pb-3 pb-md-5"
              >
                <BgImage
                  className="position-absolute d-block text-end w-100 w-xl-60 h-100 top-bg-margin"
                  image={
                    faqVideoWithBackgroundImageSection.backgroundImage
                      .localFile.childImageSharp.gatsbyImageData
                  }
                  alt={faqVideoWithBackgroundImageSection?.backgroundImage?.altText}
                  style={{
                    backgroundPosition: "right top",
                    backgroundSize: "contain",
                  }}
                >
                </BgImage>
                <div
                  style={{ overflow: "hidden" }}
                  className="h-lg-30 h-md-40 h-40 w-100 bg-white start-0 bottom-0 position-absolute"
                ></div>
                <Container
                  style={{ zIndex: 3 }}
                  className="position-relative mt-lg-10"
                >
                  <Row className="justify-content-center">
                    <Col className="text-center" lg={8}>
                      <h2 className="pb-md-5 pb-4 text-white display-5">
                        {faqVideoWithBackgroundImageSection?.heading}
                      </h2>
                    </Col>
                  </Row>
                  {faqVideoWithBackgroundImageSection?.video.mediaItemUrl && (
                    <Row>
                      <Col className="text-center">
                        {" "}
                        <LazyLoad height={700} offset={100}>
                          <div className="pb-5 ">
                            <ReactPlayer
                              controls
                              className="w-100 h-auto"
                              url={
                                faqVideoWithBackgroundImageSection?.video
                                  .mediaItemUrl
                              }
                            />
                          </div>
                        </LazyLoad>
                      </Col>
                    </Row>
                  )}
                </Container>
              </section>
            )}
          <BgImage
            className="py-5 pt-lg-7 pt-xl-9 position-relative bg-secondary bg-right-image"
            image={
              faqPageFaqSection?.backgroundImage?.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={faqPageFaqSection?.backgroundImage?.altText}
            style={{
              backgroundPosition: "left center",
            }}
          >
            <Container
              style={{ zIndex: 2 }}
              className="position-relative text-center "
            >
              <Faq
                heading={faqPageFaqSection?.heading}
                faqs={faqPageFaqSection?.faq}
              />
            </Container>
          </BgImage>
          {faqAboutSection &&
            !checkPropertiesForNull(faqAboutSection, ["heading"]) && (
              <section className="py-5 py-xl-7">
                <Container>
                  <Row className="g-5 g-lg-6 align-items-center">
                    <Col md={4}>
                      <GatsbyImage
                        className="w-100"
                        image={
                          faqAboutSection.image?.localFile.childImageSharp
                            .gatsbyImageData
                        }
                        alt={faqAboutSection.image?.altText}
                      />
                    </Col>
                    <Col md={8}>
                      <h2 className=" fs-2 mt-4  ">
                        {faqAboutSection?.heading}
                      </h2>
                      <h3 className="text-secondary fs-4">
                        {faqAboutSection?.subHeading}
                      </h3>
                      <SafeHtmlParser htmlContent={faqAboutSection?.content} />
                    </Col>
                  </Row>
                </Container>
              </section>
            )}
        </Layout>
      </div>
    </>
  );
};

export default FAQPage;
